import clsx from 'clsx';
import {useContext, useEffect, useRef} from 'react';
// import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import {gsap} from 'gsap';
import TransitionLink from 'gatsby-plugin-transition-link';
import useHPHover from '../../hooks/useHover';
import * as styles from './HPCaseStudies.module.css';
import useIsMobile from '../../hooks/useIsMobile';
import caseStudies from '../../data/caseStudies';
import hpToCaseTransition from '../../utils/animations/hpToCase';
import {MainStateContext} from '../layout/stateWrapper';

const caseStudiesData = caseStudies.filter(caseStudy => caseStudy.isFeatured);

const CaseLink = ({
    linkTo,
    pageRef,
    caseName,
    context,
    children,
}) => (
    <TransitionLink
        to={linkTo}
        exit={{
            state: {
                caseName,
            },
        }}
        entry={{
            state: {
                isTransition: true,
            },
        }}
        trigger={async pages => {
            context.setState(previous => ({
                ...previous,
                firstLoad: true,
            }));
            await hpToCaseTransition(pages, pageRef);
        }}
    >
        {children}
    </TransitionLink>
);

const CTAButton = ({
    isHovered,
    circleRef,
    linkTo,
    pageRef,
    caseName,
    context,
}) => (
    <CaseLink
        linkTo={linkTo}
        pageRef={pageRef}
        caseName={caseName}
        context={context}
    >
        <div
            className={clsx(styles.circle, isHovered && styles.show)}
            ref={circleRef}
        >
            <span className={styles.discoverText}>
                Prohlédnout
            </span>
            <span className={styles.caseStudyText}>
                Case study
            </span>
        </div>
    </CaseLink>
);

const HpCaseStudy = ({
    slug,
    linkTo,
    imageSrc,
    hp: {
        position,
        imageAlt,
        linkText,
        textStart,
        textEnd,
    },
    pageRef,
}) => {
    const linkRef = useRef(null);
    const circleRef = useRef(null);
    const isMobile = useIsMobile();
    const [wrapperRef, isHovered] = useHPHover(linkRef, circleRef, isMobile);
    const tiltRef = useRef(null);
    const context = useContext(MainStateContext) || {firstLoad: true};

    useEffect(() => {
        const rotateY = position === 'right' ? '10deg' : '-10deg';

        if (isMobile) {
            gsap.to(tiltRef.current, {
                opacity: 1,
                transform: 'none',
            });
        }

        const animation = gsap.fromTo(tiltRef.current, {
            transform: 'perspective(100vw) rotateX(0) rotateY(0) scale3d(1, 1, 1)',
            opacity: 0,
        }, {
            transform: `perspective(100vw) rotateX(0) rotateY(${rotateY}) scale3d(1, 1, 1)`,
            opacity: 1,
            duration: 0.2,
        });

        if (isHovered) {
            animation.play(0);
        }

        if (!isHovered) {
            animation.reverse(0);
        }
    }, [isHovered]);

    return (
        <div
            className={clsx(
                styles.caseRow,
                position && styles[position]
            )}
        >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
                ref={tiltRef}
                className={clsx(
                    styles.videoWrapper,
                    isHovered && styles.show
                )}
            >
                <div className={styles.video}>
                    <img
                        src={imageSrc}
                        alt={imageAlt}
                        data-animation-image="case"
                        data-animation-case={slug}
                    />
                </div>
                {isMobile && (
                    <CTAButton
                        isHovered={false}
                        circleRef={circleRef}
                        linkTo={linkTo}
                        pageRef={pageRef}
                        caseName={slug}
                        context={context}
                    />
                )}
            </div>
            <div className={styles.textWraper} ref={wrapperRef}>
                <span className={clsx(styles.caseText, isHovered && styles.grayText)}>
                    {textStart}
                </span>
                {isMobile && (
                    <>
                        {' '}
                        <CaseLink
                            className={styles.link}
                            linkTo={linkTo}
                            pageRef={pageRef}
                            caseName={slug}
                            context={context}
                        >{linkText}
                        </CaseLink>
                        {' '}
                    </>
                )}
                {!isMobile && (
                    <span className={styles.link} ref={linkRef}>
                        {linkText}
                    </span>
                )}
                <span className={clsx(styles.caseText, isHovered && styles.grayText)}>
                    {textEnd}
                </span>
            </div>
            {!isMobile && (
                <CTAButton
                    isHovered={isHovered}
                    circleRef={circleRef}
                    linkTo={linkTo}
                    pageRef={pageRef}
                    caseName={slug}
                    context={context}
                />
            )}
        </div>
    );
};

const HPCaseStudies = ({pageRef}) => (
    <section className={styles.root}>
        <div className={styles.wrapper}>
            {caseStudiesData.map((caseStudy, index) => (
                <HpCaseStudy
                    {...caseStudy}
                    pageRef={pageRef}
                    key={`caseStudy-${index}`}
                />
            ))}
        </div>
    </section>
);

HPCaseStudies.propTypes = {
    pageRef: PropTypes.any,
};

HpCaseStudy.propTypes = {
    position: PropTypes.string,
    imageAlt: PropTypes.string,
    imageSrc: PropTypes.string,
    linkText: PropTypes.string,
    linkTo: PropTypes.string,
    textStart: PropTypes.string,
    textEnd: PropTypes.string,
    hp: PropTypes.object,
    pageRef: PropTypes.any,
    slug: PropTypes.string,
};

CTAButton.propTypes = {
    isHovered: PropTypes.bool,
    circleRef: PropTypes.any,
    // handleOnClick: PropTypes.func,
    linkTo: PropTypes.string,
    pageRef: PropTypes.any,
    caseName: PropTypes.string,
    context: PropTypes.any,
};

CaseLink.propTypes = {
    linkTo: PropTypes.string,
    pageRef: PropTypes.any,
    caseName: PropTypes.string,
    context: PropTypes.any,
    children: PropTypes.any,
};

export default HPCaseStudies;
