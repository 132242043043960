// extracted by mini-css-extract-plugin
export var digitalStudio = "index-module--digitalStudio--CDIu0";
export var digitalStudioParagraph = "index-module--digitalStudioParagraph--m8DVz";
export var digitalStudioPhoto = "index-module--digitalStudioPhoto---YcI-";
export var digitalStudioText = "index-module--digitalStudioText--aScc+";
export var exiting = "index-module--exiting--QKx9W";
export var fadeIn = "index-module--fadeIn--fR9LX";
export var fadeOut = "index-module--fadeOut--ONxHm";
export var ideals = "index-module--ideals--33br6";
export var idealsHeader = "index-module--idealsHeader--VNgBk";
export var idealsIcon = "index-module--idealsIcon--CMmz7";
export var idealsProject = "index-module--idealsProject--+DKw+";
export var idealsText = "index-module--idealsText--Ay2Oz";
export var reveal = "index-module--reveal--JLPCX";
export var workWith = "index-module--workWith--ETBDY";
export var workWithArrow = "index-module--workWithArrow--we2zW";
export var workWithButton = "index-module--workWithButton--oN5I4";
export var workWithMap = "index-module--workWithMap--5PBkH";
export var workWithMapWrapper = "index-module--workWithMapWrapper--8Tx7L";
export var workWithText = "index-module--workWithText--uwGE9";
export var workWithWrapper = "index-module--workWithWrapper--TC+0R";