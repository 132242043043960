// extracted by mini-css-extract-plugin
export var caseRow = "HPCaseStudies-module--caseRow--K5SxM";
export var caseStudyText = "HPCaseStudies-module--caseStudyText--QWqM6";
export var caseText = "HPCaseStudies-module--caseText--WdjOO";
export var circle = "HPCaseStudies-module--circle--aHGWE";
export var discoverText = "HPCaseStudies-module--discoverText--ZgQBL";
export var grayText = "HPCaseStudies-module--grayText--FADwn";
export var link = "HPCaseStudies-module--link--2aerw";
export var right = "HPCaseStudies-module--right--+UHiA";
export var root = "HPCaseStudies-module--root--1np7O";
export var show = "HPCaseStudies-module--show--YMfwM";
export var textWraper = "HPCaseStudies-module--textWraper--Voq1z";
export var video = "HPCaseStudies-module--video--kuqiW";
export var videoWrapper = "HPCaseStudies-module--videoWrapper--qQx-T";
export var wrapper = "HPCaseStudies-module--wrapper---YBJ0";