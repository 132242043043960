import Link from 'gatsby-plugin-transition-link';
import clsx from 'clsx';
import {useGTMDispatch} from '@elgorditosalsero/react-gtm-hook';
import * as styles from './heroIndex.module.css';
import ArrowRight from '../../../assets/images/arrow_right_icon.svg';
import pageTypeEnum from '../../../utils/enums/pageType';
import scrollTopAnimation from '../../../utils/animations/base/scrollTop';

const HeroIndex = () => {
    const sendDataToGTM = useGTMDispatch();

    return (
        <div className={styles.hero}>
            <div className={styles.mobileHeader}>
                <div className={clsx(styles.digitalWrapper, styles.animation)}>
                    <h1 className={clsx(styles.mainHeadingOne, styles.animation)}>
                        Digital
                    </h1>
                </div>
                <div className={clsx(styles.visionWrapper, styles.animation)}>
                    <h1 className={clsx(styles.mainHeadingTwo, styles.animation)}>
                        Vision
                    </h1>
                </div>
            </div>
            <div className={clsx(styles.digitalWrapper, styles.animation, styles.desktopHeader)}>
                <h1 className={clsx(styles.mainHeadingOne, styles.animation)}>
                    Digital Vision
                </h1>
            </div>
            <h2
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="1000"
                data-aos-easing="ease-out"
                className={styles.heroText}
            >
                Pomocí designu a moderních technologií tvoříme digitální zážitky, které překvapí a zaujmou.
            </h2>
            <Link
                to="/cases"
                onClick={() => {
                    sendDataToGTM({
                        event: 'linkClick',
                        linkText: 'Projekty',
                        targetURL: '/cases',
                    });
                }}
                className={clsx(styles.link, styles.animation)}
                exit={{
                    length: 0.5,
                    state: {
                        pageType: pageTypeEnum.hp,
                        nextPageType: pageTypeEnum.cases,
                        linkTo: '/cases',
                    },
                }}
                entry={{
                    length: 0.5,
                    delay: 0.3,
                    state: {
                        pageType: pageTypeEnum.hp,
                        nextPageType: pageTypeEnum.cases,
                        linkTo: '/cases',
                    },
                }}
                trigger={() => {
                    scrollTopAnimation();
                }}
            >
                <span>Projekty</span>
                <ArrowRight/>
            </Link>

        </div>
    );
};

export default HeroIndex;
