// extracted by mini-css-extract-plugin
export var animation = "heroIndex-module--animation--0N1fr";
export var desktopHeader = "heroIndex-module--desktopHeader--+2VUt";
export var digitalWrapper = "heroIndex-module--digitalWrapper--CyY7z";
export var fadeIn = "heroIndex-module--fadeIn--cSdGF";
export var goDown = "heroIndex-module--goDown--GmpL0";
export var heading = "heroIndex-module--heading--xohOD";
export var hero = "heroIndex-module--hero--KxmzU";
export var heroText = "heroIndex-module--heroText--x9627";
export var link = "heroIndex-module--link---5hyW";
export var mainHeadingOne = "heroIndex-module--mainHeadingOne--U9wrg";
export var mainHeadingTwo = "heroIndex-module--mainHeadingTwo--0Dwea";
export var mobileHeader = "heroIndex-module--mobileHeader--pgR7+";
export var upFloat = "heroIndex-module--upFloat--dNoi7";
export var visionWrapper = "heroIndex-module--visionWrapper--GYCLv";