import {useRef, useEffect, useState} from 'react';
import {navigate, Link} from 'gatsby';
import clsx from 'clsx';
import {Helmet} from 'react-helmet';
import {useTransitionState, useTriggerTransition} from 'gatsby-plugin-transition-link/hooks';
import {useGTMDispatch} from '@elgorditosalsero/react-gtm-hook';
import {Layout, Header, Container} from '../components/layout';
import pageTypes from '../utils/enums/pageType';
import scrollTo from '../utils/scrollTo';
import SmileyIcon from '../assets/images/icons/smiley.svg';
import ThumbsUpIcon from '../assets/images/icons/thumbsup.svg';
import useIsMobile from '../hooks/useIsMobile';
import HeroIndex from '../components/layout/hero/heroIndex';
import MapPattern from '../assets/images/map.svg';
import LogoDShape from '../assets/images/dv-d.svg';
import {Button} from '../components';
import HPCaseStudies from '../components/partials/HPCaseStudies';
import {isExiting} from '../utils/animations';
import * as styles from './index.module.css';

const IndexPage = () => {
    const isMobile = useIsMobile();
    const sendDataToGTM = useGTMDispatch();
    const hpCasesRef = useRef(null);
    const homePageRef = useRef(null);
    const {entry, transitionStatus} = useTransitionState();
    const exiting = isExiting(transitionStatus);

    const [isLoaded, setIsloaded] = useState(false);

    const entryTransition = useTriggerTransition({
        to: '/',
        entry: {
            length: 0.5,
            state: {
                pageType: pageTypes.hp,
                nextPageType: pageTypes.hp,
            },
        },
    });

    useEffect(() => {
        setIsloaded(true);

        if (entry && Object.keys(entry.state).length > 0 && entry.state.isTransition && transitionStatus !== 'exited') {
            return;
        }

        entryTransition({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isLoaded && (
                <Layout hideFooterButton={false} innerRef={homePageRef}>
                    <Helmet>
                        <title>Digital Vision CZ | Based on experience, driven by curiosity</title>
                        <meta
                            name="description"
                            content="Jsme menší digitální studio. Sídlíme v Praze na Žižkově. Potkávají se u nás lidé s řadou specializací od designu, přes vývoj, po online marketing."
                        />
                    </Helmet>
                    <Header pageType={pageTypes.hp}>
                        <HeroIndex/>
                    </Header>
                    <div
                        className={clsx(styles.workWithWrapper, exiting && styles.exiting)}
                    >
                        <div className={styles.workWith}>
                            <span className={styles.workWithText}>
                                Spolupracujeme s inovátory a vizionáři, kteří svými produkty zlepšují lidem život.
                            </span>
                            <button
                                className={styles.workWithArrow}
                                onClick={() => {
                                    sendDataToGTM({
                                        event: 'imageClick',
                                        imageAlt: 'logoDShape',
                                        type: 'anchor',
                                        targetURL: '/',
                                    });

                                    scrollTo(hpCasesRef);
                                }}
                            >
                                <LogoDShape/>
                            </button>
                            <span className={styles.workWithButton}>
                                <Link
                                    onClick={() => {
                                        sendDataToGTM({
                                            event: 'buttonClick',
                                            buttonText: 'Kontakt',
                                            targetURL: '/contact',
                                        });
                                    }
                                    }
                                    to="/contact"
                                >
                                    <Button
                                        text="Kontakt"
                                        onClick={() => navigate('/contact')}
                                        color="yellow"
                                    />
                                </Link>
                            </span>
                        </div>
                        {!isMobile && (
                            <div className={styles.workWithMapWrapper}>
                                <span className={styles.workWithMap}>
                                    <MapPattern/>
                                </span>
                            </div>
                        )}
                    </div>
                    <span ref={hpCasesRef}/>
                    <HPCaseStudies pageRef={homePageRef}/>
                    <div className={styles.digitalStudio}>
                        <div className={styles.digitalStudioText}>
                            <p className={styles.digitalStudioParagraph}>
                                <span>
                                    Jsme menší <strong>digitální studio</strong>. Sídlíme v Praze na Žižkově.
                                    Potkávají se u nás lidé s řadou specializací od designu,
                                    přes vývoj, po online marketing. Rádi dáváme hlavy dohromady
                                    a konfrontujeme různé úhly pohledu, abychom vydestilovali&nbsp;to <strong>nejlepší možné řešení</strong>.
                                </span>
                            </p>
                        </div>
                        <div className={styles.digitalStudioPhoto}>
                            <picture>
                                <source media="(min-width:1200px)" srcSet="/images/hp/zizkov-xl.jpg"/>
                                <source media="(min-width:900px)" srcSet="/images/hp/zizkov-lg.jpg"/>
                                <img src="/images/hp/zizkov-full.jpg" alt="Žižkov"/>
                            </picture>
                        </div>
                    </div>
                    <Container>
                        <div className={styles.ideals}>
                            <h3 className={styles.idealsHeader}>
                                Naším cílem je posouvat technologie za hranice aktuálních limitů. A současné technologie využívat a kombinovat tak,
                                ať z nich vyždímáme to nejlepší, co nabízejí.
                            </h3>
                            <div className={styles.idealsProject}>
                                <div className={styles.idealsIcon}>
                                    <ThumbsUpIcon/>
                                </div>
                                <div className={styles.idealsText}>
                                    <p>
                                        <strong>Ideální projekt?</strong> Pokaždé jiný! Takový, kde využijeme svoje znalosti,
                                        ale zároveň <strong>přináší novou výzvu.</strong> Milujeme rozmanitost.
                                    </p>
                                </div>
                            </div>
                            <div className={styles.idealsProject}>
                                <div className={styles.idealsIcon}>
                                    <SmileyIcon/>
                                </div>
                                <div className={styles.idealsText}>
                                    <p>
                                        <strong>Ideální klient?</strong> Ten, komu můžeme být parťákem a <strong>vzájemně se obohatit</strong>.
                                        Pojďme společně vytvořit něco, z čeho budeme mít radost my, vy i vaši zákazníci.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Container>
                </Layout>
            )}
        </>
    );
};

export default IndexPage;
