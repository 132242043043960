import {useState, useEffect, useRef} from 'react';

const getOffset = (element, horizontal = false) => {
    if (!element) {
        return 0;
    }

    // eslint-disable-next-line no-unused-vars
    return getOffset(element.offsetParent, horizontal) + (horizontal ? element.offsetLeft : element.offsetTop);
};

const getMouseDistanceFromElement = (mouseEvent, linkEl, circleEl, wrapperEl) => {
    if (!linkEl || !circleEl || !wrapperEl) {
        return false;
    }

    const circle = circleEl;
    const mX = mouseEvent.pageX;
    const mY = mouseEvent.pageY;
    const from = {x: mX, y: mY};
    const offset = linkEl.getBoundingClientRect();
    const ny1 = offset.top + window.scrollY;
    const ny2 = ny1 + linkEl.offsetHeight;
    const nx1 = offset.left + window.scrollX;
    const nx2 = nx1 + linkEl.offsetWidth;
    const maxX1 = Math.max(mX, nx1);
    const minX2 = Math.min(mX, nx2);
    const maxY1 = Math.max(mY, ny1);
    const minY2 = Math.min(mY, ny2);
    const intersectX = minX2 >= maxX1;
    const intersectY = minY2 >= maxY1;
    const to = {
        // eslint-disable-next-line no-nested-ternary
        x: intersectX ? mX : nx2 < mX ? nx2 : nx1,
        // eslint-disable-next-line no-nested-ternary
        y: intersectY ? mY : ny2 < mY ? ny2 : ny1,
    };
    const distX = to.x - from.x;
    const distY = to.y - from.y;
    const hypot = (distX ** 2 + distY ** 2) ** (1 / 2);

    const isHovered = !(Math.floor(hypot) - 20 > 0);
    const isAvailable = !(Math.floor(hypot) - 400 > 0);

    if (isAvailable) {
        const left = mX - getOffset(wrapperEl, true);
        const top = mY - getOffset(wrapperEl);

        circle.style.left = `${left}px`;
        circle.style.top = `${top}px`;
    }

    return isHovered;
};

const useHPHover = (linkRef, circleRef, disabled = false) => {
    const [value, setValue] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(() => {
        const node = linkRef.current;

        const handleMouseDistance = e => {
            const isHovered = getMouseDistanceFromElement(e, linkRef.current, circleRef.current, wrapperRef.current);

            setValue(isHovered);
        };

        if (node) {
            document.addEventListener('mousemove', handleMouseDistance);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseDistance);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (disabled) {
        return [];
    }

    return [wrapperRef, value];
};

export default useHPHover;
